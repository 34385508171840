import toBase64String from "@utils/toBase64String"

export function getSetRecommendedProducts(
  allProducts,
  recommendedProducts,
  comparisonItems,
) {
  let allRecommendedProducts = []

  const allAlwaysShownItems = allProducts
    ? allProducts.nodes
        .flatMap(node =>
          node.products.filter(item =>
            item.tags.map(tag => tag.toLowerCase()).includes("recommended"),
          ),
        )
        .filter(
          (product, index, self) =>
            index ===
            self.findIndex(
              t => t?.handle === product.handle && t?.title === product.title,
            ),
        )
        .map(product => ({ ...product, tag: "staticQuery" }))
    : {}

  const allProductSpecificRecommendedProductIds = allProducts
    ? allProducts.nodes
        .flatMap(node => node.products.filter(item => item))
        .filter(item =>
          comparisonItems.lines
            ? comparisonItems.lines.edges.find(
                comparisonItemsItem =>
                  item.handle ===
                  comparisonItemsItem.node.merchandise.product.handle,
              )
            : comparisonItems.product.handle === item.handle,
        )
        .flatMap(item =>
          item.metafields.filter(product =>
            product.key.startsWith("related_product"),
          ),
        )
        .flatMap(item => item.value)
        .filter(
          (product, index, self) =>
            index === self.findIndex(t => t === product) &&
            product !== undefined,
        )
    : {}

  const allProductSpecificRecommendedProducts = allProducts
    ? allProducts.nodes
        .flatMap(node => node.products.filter(item => item))
        .filter(item =>
          allProductSpecificRecommendedProductIds.includes(
            atob(
              item.shopifyId.includes("gid://shopify/")
                ? toBase64String(item.shopifyId)
                : item.shopifyId,
            ),
          ),
        )
        .filter(
          (product, index, self) =>
            index === self.findIndex(t => t.handle === product.handle) &&
            product !== undefined,
        )
        .map(item => ({ ...item, tag: "staticQuery" }))
    : {}

  const mergedArray = [
    ...allAlwaysShownItems,
    ...allProductSpecificRecommendedProducts,
    ...recommendedProducts,
  ]

  let removeItemsThatAreInAlreadyInCartOrOnPage = mergedArray

  if (comparisonItems.lines) {
    removeItemsThatAreInAlreadyInCartOrOnPage = mergedArray.filter(
      item =>
        !comparisonItems.lines.edges.find(
          comparisonItemsItem =>
            item.handle === comparisonItemsItem.node.merchandise.product.handle,
        ),
    )
  }

  if (comparisonItems.product) {
    removeItemsThatAreInAlreadyInCartOrOnPage = mergedArray.filter(
      item => comparisonItems.product.handle !== item.handle,
    )
  }

  const removeDuplicates = removeItemsThatAreInAlreadyInCartOrOnPage.filter(
    (product, index, self) =>
      index === self.findIndex(t => t.handle === product.handle),
  )

  allRecommendedProducts = [...removeDuplicates]

  return allRecommendedProducts
}
