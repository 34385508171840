const formatPrice = (price, currencyCode = "GBP") => {
  const locales = locale => {
    switch (locale?.toLowerCase()) {
      case "us":
        return "en-US"
      case "gb":
        return "en-GB"
      case "it":
        return "it-IT"
      case "es":
        return "es-ES"
      default:
        return locale || "en-GB" // fallback
    }
  }

  const safeCurrency = typeof currencyCode === "string" && currencyCode.length === 3
    ? currencyCode.toUpperCase()
    : "GBP"

  const numericPrice = parseFloat(price)
  if (isNaN(numericPrice)) {
    return ""
  }

  try {
    return new Intl.NumberFormat(locales(process.env.GATSBY_LOCALE), {
      currency: safeCurrency,
      minimumFractionDigits: 2,
      style: "currency",
    }).format(numericPrice)
  } catch (err) {
    return `${numericPrice} ${safeCurrency}`
  }
}

export default formatPrice
