import { useStaticQuery, graphql } from "gatsby"

export const useSiteTranslations = () => {
  const { wp } = useStaticQuery(graphql`
    query TranslationsQuery {
      wp {
        headerTranslations: acfHeaderTranslations {
          header: acfHeaderTranslationsGroup {
            footerBlurb
            linksTitle
            shopCtaText
          }
        }
        blogTranslations: acfBlogTranslations {
          blog: acfBlogTranslationsGroup {
            headerTitleText
            headerSubtitleText
            latestPostsTabText
            postCardCtaText
          }
        }
        generalTranslations: acfGeneralTranslations {
          general: acfGeneralTranslationsGroup {
            contactForm {
              privacyPolicyText
              phoneNumberPlaceholderText
              lastNamePlaceholderText
              firstNamePlaceholderText
              emailAddressPlaceholderText
              destinationSelectDropdownText
              competitionSelectDropdownText
              ctaText
            }
            productLocator {
              postcodeInputPlaceholderText
              locationCtaText
              postcodeErrorText
              locatorLoadingText
              locatorLoadingNoResultsText
            }
            ageGate {
              titleText
              subtitleText
              yesCtaText
              noCtaText
              underEighteenBlurb
            }
            homepage {
              latestNewsTitleText
              latestNewsSubtitleText
            }
          }
        }
        shopTranslations: acfShopTranslations {
          shop: acfShopTranslationsGroup {
            categoryInputPlaceholderText
            productsFound
            noProducts
            allProductsCategoryText
            searchInputPlaceholderText
            seeMoreCtaText
            sortByInputPlaceholderText
            addToCartCtaText
            addedToCartCtaText
            compareAtPrice {
              from
              percentOff
              was
              now
            }
            upsell {
              checkoutCtaText
              upsellTitle
            }
            cartSummary {
              summaryTitle
              itemSubtotalSubtitle
              freeDeliveryOptionText
              deliverySubtitle
              deliveryOptionText
              continueShoppingCtaText
              checkoutCtaText
              carbonImpactDeliveryText
              carbonImpactDeliveryCtaText
              ageConfirmationCheckboxDescription
              ageConfirmationCheckboxTitle
            }
            sortBySelectOptions {
              all
              priceAscending
              priceDescending
              titleAZ
              titleZA
            }
            tabbedArea {
              tabTwoContent
              tabTwoTitle
              tabOneTitle
            }
            breadcrumbs {
              shopTitle
              beerTitle
              merchTitle
            }
            recommendedProducts {
              recommendedTitle
              recommendedChristmasTitle
            }
            outOfStockForm {
              backInStockMessage
              ctaText
              outOfStockTitle
              privacyPolicyCtaText
              privacyPolicyMessage
              submissionErrorMessage
              submissionSuccessMessage
              submissionSuccessTitle
            }
            cartTitleText
            cartCtaText
            noItemsInCartText
            cartList {
              costTitle
              itemTitle
              qtyTitle
            }
            freeShipping {
              calloutLinkText
              calloutTitle
              continueCtaText
              deliveryCtaText
              calloutHeadingPrePrice
              calloutHeadingPostPrice
            }
            giftMessageCheckboxText
            giftMessageNotice
            giftMessageInputPlaceholderText
          }
        }
      }
    }
  `)

  return wp
}
