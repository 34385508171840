const getCurrencyCode = () => {
  const localeCurrencyMap = new Map([
    ["gb", "GBP"], // British Pound
    ["es", "EUR"], // Euro
    ["it", "EUR"], // Euro
    ["us", "USD"], // US Dollar
  ])

  const locale = process.env.GATSBY_LOCALE ?? "gb"

  return localeCurrencyMap.get(locale) ?? "GBP"
}

export default getCurrencyCode
