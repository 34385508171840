import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Link } from "gatsby"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import * as l from "../SideBar.module.scss"
import * as s from "./Cart.module.scss"

import { initializeCart, addLineItemsToCart } from "@ducks/cartSlice"
import { setState } from "@ducks/sidebarSlice"
import { useSiteTranslations } from "@hooks/useSiteTranslations"
import LeafImage from "@assets/images/leaf.png"
import CartIcon from "@assets/svg/shoppingCart.inline.svg"

import formatPrice from "@utils/formatPrice"
// import isFreeShipping from "@utils/isFreeShipping"
// import freeShippingAmount from "@utils/freeShippingAmount"

import { Cta } from "@components/common/cta/Cta"
import { QuantitySelector } from "@components/main/quantity-selector/QuantitySelector"
import { CheckBox } from "@components/common/form/CheckBox"

const Cart = () => {
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart.cart)
  const checkoutTransition = useSelector(
    state => state.sidebar.checkoutTransition,
  )
  const showUpSell = useSelector(state => state.sidebar.upsellIsVisible)

  const {
    shopTranslations: { shop },
  } = useSiteTranslations()
  const [isOver18, setIsOver18] = useState(false)
  const [isOver18Error, setIsOver18Error] = useState(false)

  const openUpSell = () => {
    if (!isOver18) {
      setIsOver18Error(true)
      return
    } else {
      setIsOver18Error(false)
    }

    dispatch(
      setState({
        upsellIsVisible: true,
      }),
    )
  }

  useEffect(() => {
    dispatch(initializeCart())
  }, [])

  useEffect(() => {
    if (isOver18) {
      setIsOver18Error(false)
    }
  }, [isOver18])

  return (
    <>
      <div className={l.Content__header}>
        <h3>{shop.cartTitleText}</h3>
      </div>
      {cart && (
        <div className={`${s.Cart} ${showUpSell ? s.Cart___locked : ""}`}>
          {cart.lines && cart.lines.edges.length < 1 ? (
            <EmptyCart />
          ) : (
            <>
              <div>
                {/* {isFreeShipping(checkout.totalPrice) ? (
                  <div className={s.Cart__callOut}>
                    <h5>
                      {`You're £${freeShippingAmount(
                        checkout.totalPrice,
                      )} away from
                      free shipping*`}
                    </h5>

                    <Cta
                      title="Continue Shopping"
                      link="/shop"
                      onClick={() => {
                        trackCustomEvent({
                          category: "Cart",
                          action: "Shipping Upsell Click",
                        })
                        dispatch(
                          setState({
                            sidebarIsVisible: false,
                          }),
                        )
                      }}
                    />
                  </div>
                ) : (
                  <div className={s.Cart__callOut}>
                    <h5>You've qualified for free shipping* - get in!</h5>
                    <Link
                      className={s.Cart__callOutTAC}
                      to="/delivery-and-returns"
                    >
                      * Not applicable to Highlands, Islands and Northern
                      Ireland
                    </Link>
                  </div>
                )} */}
                <div className={s.Cart__sustainabilityCallOut}>
                  <div className={s.Cart__sustainabilityCallOut___leaf}>
                    <img src={LeafImage} alt="Image of a leaf" />
                  </div>
                  <h5 className={s.Cart__sustainabilityCallOut___text}>
                    We are offsetting the carbon impact of this delivery
                  </h5>
                  <Cta title="Find out more" link="/sustainability" />
                </div>

                {cart.lines?.edges.map(({ node }) => {
                  return (
                    <div className={s.CartItem} key={node.id}>
                      <div
                        className={s.CartItem__image}
                        style={{
                          backgroundImage: `url(${node.merchandise?.image.url})`,
                        }}
                      />

                      <div className={s.CartItem__details}>
                        <h4>{node.merchandise.product.title}</h4>
                        <span>{node.merchandise.title}</span>

                        {node.attributes.length > 0 && (
                          <span>
                            <i>{node.attributes[0].value}</i>
                          </span>
                        )}
                      </div>

                      <div className={s.CartItem__options}>
                        <h4 className={s.CartItem__price}>
                          {node.merchandise.compareAtPrice ? (
                            <>
                              <span className={s.CartItem__pricePercent}>
                                {`${formatPrice(
                                  node.merchandise.price.amount,
                                )} % off`}
                              </span>
                              <span className={s.CartItem__priceCurrent}>
                                {formatPrice(node.merchandise.price.amount)}
                              </span>
                            </>
                          ) : (
                            <>{formatPrice(node.merchandise.price.amount)}</>
                          )}
                        </h4>
                        <QuantitySelector
                          quantity={node.quantity}
                          updateQuantity={val => {
                            dispatch(
                              addLineItemsToCart({
                                product: {
                                  shopifyId: node.merchandise.id,
                                  quantity: val,
                                },
                                cartId: cart.id,
                              }),
                            )
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
              <div>
                {cart.lines && cart.lines.edges.length > 0 && (
                  <div className={`${s.Cart__18check}`}>
                    <div>
                      <h4
                        onClick={() => {
                          setIsOver18(true)
                        }}
                        className={
                          isOver18Error ? s.Cart__18check___errorText : null
                        }
                      >
                        Please check the box to confirm you are over the age of
                        18
                      </h4>
                      <p>
                        Sale of alcohol is prohibited to those aged under 18 and
                        we reserve the right to cancel your order if no ID is
                        present at delivery. Full T&Cs{" "}
                        <Link to="terms-and-conditions">here</Link>.
                      </p>
                    </div>
                    <CheckBox
                      className={`${s.Cart__18check__box}`}
                      value={isOver18}
                      onChange={val => setIsOver18(val)}
                      error={isOver18Error}
                    />
                  </div>
                )}
                <div className={s.CartTotals}>
                  <div className={s.CartTotals__row}>
                    <p>Subtotal</p>
                    <h5>{formatPrice(cart.cost?.subtotalAmount.amount)}</h5>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {cart && cart.lines?.edges.length > 0 && (
        <div className={`${s.Cart__buttons} ${s.Cart__buttons___double}`}>
          <div>
            <Cta
              title="Continue Shopping"
              className={s.Cart__button___secondary}
              onClick={() => {
                dispatch(
                  setState({
                    sidebarIsVisible: false,
                  }),
                )
              }}
            />
          </div>
          <div>
            <Cta title="Checkout" onClick={openUpSell} />
          </div>
        </div>
      )}

      <div
        className={`${s.Transition} ${
          checkoutTransition && s.Transition___transitioning
        }`}
      ></div>
    </>
  )
}

const EmptyCart = () => {
  const dispatch = useDispatch()
  const {
    shopTranslations: { shop },
  } = useSiteTranslations()

  return (
    <div className={s.Cart__empty}>
      <div className={s.Cart__empty__content}>
        <CartIcon />
        <p>{shop.noItemsInCartText}</p>
      </div>

      <div className={s.Cart__buttons}>
        <div>
          <Cta
            title="Shop"
            link="/shop"
            onClick={() => {
              dispatch(
                setState({
                  sidebarIsVisible: false,
                }),
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Cart
