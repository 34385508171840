import React, { useState, useEffect } from "react"
import classNames from "classnames"

import * as s from "./CountryPicker.module.scss"

export const CountryPicker = ({ activeCountry, data }) => {
  const { countryPickerOptions } = data
  const [showCountryPicker, setShowCountryPicker] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)

  const toggleCountryPicker = () => {
    setShowCountryPicker(!showCountryPicker)
  }

  useEffect(() => {
    const foundActiveCountry = countryPickerOptions.find(
      item => item.title === activeCountry,
    )

    setSelectedCountry(foundActiveCountry)
  }, [activeCountry])

  useEffect(() => {
    const handleClickOutside = event => {
      if (!event.target.closest(`.${s.countryPicker}`)) {
        setShowCountryPicker(false)
      }
    }

    if (showCountryPicker) {
      document.addEventListener("click", handleClickOutside)
    }

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [showCountryPicker])

  return (
    <div
      className={classNames(s.countryPicker, {
        [s.active]: showCountryPicker,
      })}
      onClick={toggleCountryPicker}
    >
      <img
        width="150"
        height="150"
        src={selectedCountry?.flagImage.sourceUrl}
        alt={selectedCountry?.flagImage.altText}
      />

      {showCountryPicker && (
        <div className={s.countryPicker__menu}>
          {countryPickerOptions.map(country => (
            <a href={country.url} key={country.title}>
              <div className={s.countryPicker__item}>
                <img
                  width="150"
                  height="150"
                  src={country.flagImage.sourceUrl}
                  alt={country.flagImage.altText}
                />
                <span>{country.title}</span>
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  )
}
