import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { useDispatch, useSelector } from "react-redux"
import { getSrc } from "gatsby-plugin-image"
import { useSiteTranslations } from "@hooks/useSiteTranslations"
import { checkAgeGate, acceptAgeGate } from "../../../ducks/ageGateSlice"

import { Tabs } from "../../common/tabs/Tabs"
import { LogoHeader } from "../logo-header/LogoHeader"

import * as s from "./AgeGate.module.scss"

const AgeGate = () => {
  const { wpPage } = useStaticQuery(graphql`
    query AgeGateQuery {
      wpPage(title: { eq: "Home" }) {
        acfHome {
          heroSlides {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    quality: 80
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  const {
    generalTranslations: { general },
  } = useSiteTranslations()
  const dispatch = useDispatch()
  const havePassed = useSelector(state => state.agegate.havePassed)

  useEffect(() => {
    dispatch(checkAgeGate())
  }, [])

  useEffect(() => {
    if (!havePassed) {
      const doc = document.getElementsByTagName("html")[0]
      disableBodyScroll(document.body)
      doc.style.overflow = "hidden"
    }
  }, [havePassed])

  const coverClass = null

  const _setAccepted = () => {
    const cover = document.getElementById("Cover")
    const background = document.getElementById("ageGate")

    cover.style.border = "20px solid white"
    background.style.border = "0"
    background.style.opacity = "0"

    const timer = setTimeout(() => {
      dispatch(acceptAgeGate())
      const doc = document.getElementsByTagName("html")[0]
      enableBodyScroll(document.body)
      doc.style.overflow = "scroll"
    }, 500)

    return () => clearTimeout(timer)
  }

  const openClass = !havePassed ? s.ageGate__open : s.ageGate__closed

  const backroundImage =
    wpPage.acfHome.heroSlides && wpPage.acfHome.heroSlides[0].image
      ? getSrc(wpPage.acfHome.heroSlides[0].image?.localFile)
      : getSrc(wpPage.acfHome.heroSlides[1].image.localFile)

  return (
    <div
      id="ageGate"
      className={`${s.ageGate} ${openClass}`}
      style={{ background: `url(${backroundImage}), #000` }}
    >
      <LogoHeader className={s.ageGate__logo} />

      <div className={s.ageGate__inner}>
        <div className={s.ageGate__island}>
          <div className={s.ageGate__island___inner}>
            <div>
              <p className={s.ageGate__island___innerText}>
                {general.ageGate.subtitleText}
              </p>
              <h3>{general.ageGate.titleText}</h3>
            </div>

            <div className={s.ageGate__tabs}>
              <Tabs
                onTabClick={i => {
                  if (i === 0) {
                    _setAccepted()
                  }
                }}
              >
                <div id={0} label={general.ageGate.yesCtaText}></div>

                <div id={1} label={general.ageGate.noCtaText}>
                  <div className={s.ageGate__panel}>
                    <span className={s.ageGate__panel___text}>
                      {general.ageGate.underEighteenBlurb}
                    </span>
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <div id="Cover" className={`${s.Cover} ${coverClass}`} />
    </div>
  )
}

export default AgeGate
