import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import cx from "classnames"

import flatListToHierarchical from "@utils/flatListToHierarchical"
import { parseLink } from "@utils/urlParser"
import { useSiteTranslations } from "@hooks/useSiteTranslations"
import CountryLogo from "../../../assets/images/country-picker/gbFlag.jpg"

import * as s from "./Nav.module.scss"

import Arrow from "../../../assets/icons/arrow.inline.svg"

export const Nav = ({
  mainMenuItems,
  currentPage,
  setShowMenu,
  countryPicker,
}) => {
  const [isClient, setClient] = useState(false)
  const key = isClient ? "client" : "server"

  useEffect(() => {
    setClient(true)
  }, [])

  const hierarchicalMenu = flatListToHierarchical(mainMenuItems.nodes)
  const {
    headerTranslations: { header },
  } = useSiteTranslations()
  const parentPage = currentPage.page?.ancestors?.nodes[0]
  const [activeHub, setActiveHub] = useState(null)

  let hoverTimeout

  const goToUrl = title => {
    setActiveHub(title)
    setShowMenu(false)
  }

  const onHover = item => {
    if (!isClient) return

    hoverTimeout = setTimeout(() => {
      setActiveHub(item.title)
    }, 400)
  }

  useEffect(() => {
    if (currentPage.page && !parentPage?.title) {
      setActiveHub(currentPage.page.title)
      return
    }

    if (currentPage.page && parentPage?.title) {
      setActiveHub(parentPage.title)
      return
    }

    if (currentPage.title === "Shop") {
      setActiveHub("Shop")
      return
    }

    if (currentPage.handle) {
      setActiveHub("Shop")
      return
    }

    if (currentPage.category && currentPage.title) {
      setActiveHub("Shop")
      return
    }

    if (currentPage.category) {
      setActiveHub("News")
    }
  }, [currentPage])

  const linkIsActive = (item, menu) => {
    if (menu === "main") {
      if (!activeHub) return
      return activeHub === item.title
    }

    if (menu === "sub") {
      if (currentPage.category === "Latest" && item.title === "News") {
        return true
      }
      if (currentPage.category && currentPage.title) {
        return item.title === currentPage.title
      }
      if (currentPage.category) {
        return currentPage.category.slug === item.title
      } else {
        return currentPage.title === item.title
      }
    }
  }

  if (!isClient) return null

  return (
    <div className={s.Nav} key={key}>
      <div className={`${s.NavMain} ${s.NavMain___hasActive}`}>
        <div>
          <span className={s.Nav__subTitle}>{header.linksTitle}</span>
          <ul>
            {hierarchicalMenu.map((item, i) => {
              return (
                <div key={`parent-${i}`}>
                  <li
                    key={"mobile-" + i}
                    onClick={() => goToUrl(item.title)}
                    className={s.Nav__mobile}
                  >
                    <Link to={parseLink(item.url)}>
                      <span
                        className={cx(s.Nav__link, {
                          [s.Nav__link___active]: linkIsActive(item, "main"),
                        })}
                      >
                        {item.title}
                        <Arrow />
                      </span>
                    </Link>
                  </li>
                  <li
                    key={i}
                    onClick={() => goToUrl(item.title)}
                    onMouseOver={() => onHover(item)}
                    onMouseLeave={() => clearTimeout(hoverTimeout)}
                    className={s.Nav__desktop}
                  >
                    <Link to={parseLink(item.url)}>
                      <span
                        className={cx(s.Nav__link, {
                          [s.Nav__link___active]: linkIsActive(item, "main"),
                        })}
                      >
                        {item.title}
                        <Arrow />
                      </span>
                    </Link>

                    <SubMenu
                      title={item.title}
                      links={item.children}
                      activeHub={activeHub}
                      setShowMenu={setShowMenu}
                      linkIsActive={linkIsActive}
                    />
                  </li>
                </div>
              )
            })}
          </ul>
        </div>

        <div className={s.NavMain__countryPicker}>
          <span>{countryPicker.countryPickerTitle}</span>
          <div className={s.NavMain__countryPicker__container}>
            {countryPicker.countryPickerOptions.map(country => (
              <a href={country.url} key={country.title}>
                <div>
                  <img
                    width="150"
                    height="150"
                    src={country.flagImage.sourceUrl}
                    alt={country.flagImage.altText}
                  />
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const SubMenu = ({ title, links, activeHub, setShowMenu, linkIsActive }) => {
  return (
    <div
      className={`${s.NavSub} ${s.NavSub___hasActive} ${
        activeHub && activeHub === title
          ? s.NavSub___animate
          : s.NavSub___noAnimate
      }`}
    >
      <span className={s.Nav__subTitle}>{title}</span>

      <ul>
        {links &&
          links.map((link, i) => {
            return (
              <li
                key={i}
                onClick={() => {
                  setShowMenu(false)
                }}
              >
                <Link to={parseLink(link.url)}>
                  <span
                    className={cx(s.Nav__link, {
                      [s.Nav__link___active]: linkIsActive(link, "sub"),
                    })}
                  >
                    {link.title}
                    <Arrow />
                  </span>
                </Link>
              </li>
            )
          })}
      </ul>
    </div>
  )
}
