import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import classNames from "classnames"
import { Link, navigate } from "gatsby"
import { useSelector } from "react-redux"

import CartImg from "../../../assets/svg/shoppingCart.inline.svg"
import SolidCartImg from "../../../assets/icons/shopping-cart-solid.inline.svg"
import Logo from "../../common/logo/Logo"
import { CountryPicker } from "@components/main/header/CountryPicker"
import { useSiteTranslations } from "@hooks/useSiteTranslations"
import { Props } from "./Header.interface"
import { Burger } from "./Burger"
import Menu from "../menu/Menu"
import CallOut from "./CallOut"

import * as s from "./Header.module.scss"
import Button from "../../common/button/Button"

import XmasNav from "../../../assets/images/xmas-nav.png"

const isChristmas = false

const Header = ({ pageData, template, showMenu, setShowMenu }: Props) => {
  const { mainMenu, callout, countryPicker } = useStaticQuery(graphql`
    query HeaderQuery {
      mainMenu: allWpMenuItem(filter: { locations: { eq: HEADER_MENU } }) {
        nodes {
          key: id
          parentId
          title: label
          url
        }
      }

      callout: wpNodeWithTemplate(template: { templateName: { eq: "Home" } }) {
        ... on WpPage {
          acfHome {
            calloutTitle
            calloutMessage
          }
        }
      }

      countryPicker: wpNodeWithTemplate(
        template: { templateName: { eq: "Home" } }
      ) {
        ... on WpPage {
          remoteCountryPicker {
            countryPickerTitle
            countryPickerOptions {
              url
              title
              flagImage {
                altText
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)
  const {
    headerTranslations: { header },
  } = useSiteTranslations()

  const [offTop, setOffTop] = useState(false)
  const [isScrollingDown, setIsScrollingDown] = useState(false)
  const [countryName, setCountryName] = useState("")
  const isShopPage = template === "Shop"
  const cart = useSelector(state => state.cart.cart)
  const hasPassedAgeGate = useSelector(state => state.agegate.havePassed)
  // state.cart.checkout
  const cartCount =
    cart && cart.lines?.edges.length > 0
      ? cart.lines?.edges
          .map(({ node }) => node.quantity)
          .reduce((total, node) => {
            return node + total
          })
      : 0

  if (typeof window !== `undefined`) {
    useScrollPosition(({ prevPos, currPos }) => {
      if (currPos.y >= 0) {
        return
      } else if (currPos.y > prevPos.y) {
        if (isScrollingDown) setIsScrollingDown(false)
      } else {
        if (!isScrollingDown && !showMenu) setIsScrollingDown(true)
      }

      if (currPos.y > -20) {
        return
      } else {
        if (!offTop) setOffTop(true)
      }
    })
  }

  useEffect(() => {
    switch (pageData.locale || process.env.GATSBY_LOCALE) {
      case "es":
        setCountryName("España")
        break
      case "gb":
        setCountryName("United Kingdom")
        break
      case "us":
        setCountryName("United States of America")
        break
      case "it":
        setCountryName("Italia")
        break
      case "zh":
        setCountryName("China")
        break
      default:
        break
    }
  }, [pageData.locale])

  const headerClasses = classNames({
    [s.Header]: true,
    [s.Header___ageGateClosed]: hasPassedAgeGate,
    [s.Header___menuActive]: showMenu && hasPassedAgeGate,
    [s.Header___offTop]: (offTop && hasPassedAgeGate) || isShopPage,
    [s.Header___scrollingDown]: isScrollingDown,
  })

  useEffect(() => {
    setOffTop(false)
    setIsScrollingDown(false)
  }, [pageData])

  return (
    <div className={headerClasses}>
      {process.env.NODE_ENV !== "production" && (
        <div className={s.Header__localeBar}>
          <p>
            You are viewing the <strong>{countryName}</strong> site.
          </p>
        </div>
      )}

      <div
        className={s.Header__inner}
        style={
          isChristmas
            ? {
                backgroundImage: `url(${XmasNav})`,
                boxShadow: `0px 4px 9px rgba(0, 0, 0, 0.25)`,
              }
            : {}
        }
      >
        <div
          className={s.Header__logoWrapper}
          onClick={() => {
            setShowMenu(false)
          }}
        >
          <Link to="/">
            <Logo
              isActive={
                (showMenu && hasPassedAgeGate) ||
                (offTop && hasPassedAgeGate) ||
                isShopPage
              }
            />
          </Link>
        </div>

        <Burger
          onClick={() => {
            setShowMenu(!showMenu)
          }}
          isChristmas={isChristmas}
          active={showMenu}
        />

        <div
          className={`${s.Header__buttonGroup} ${
            isChristmas && s.Header__buttonGroup___christmas
          }`}
        >
          <CountryPicker
            data={countryPicker.remoteCountryPicker}
            activeCountry={countryName}
          />

          <div
            className={`${s.Header__buttonGroup___cart} ${
              cartCount > 0 && s.Header__buttonGroup___cart___hasItems
            }
            ${
              isChristmas &&
              cartCount > 0 &&
              s.Header__buttonGroup___cart___hasItems_christmas
            }
             `}
            onClick={() => {
              navigate("/shop/cart")
            }}
          >
            {isScrollingDown || offTop || isChristmas ? (
              <SolidCartImg />
            ) : (
              <CartImg />
            )}
            <div>
              <span>{cartCount}</span>
            </div>
          </div>

          <Button
            className={`${s.Header__buttonGroup___shop} ${
              isScrollingDown || offTop || isShopPage || showMenu || isChristmas
                ? ""
                : s.Header__buttonGroup___shopTransparent
            }`}
            to="/shop"
          >
            {header.shopCtaText}
          </Button>
        </div>
      </div>

      <CallOut className={s.Header__Calllout} data={callout.acfHome} />

      <Menu
        isOpen={showMenu}
        mainMenu={mainMenu}
        currentPage={pageData}
        setShowMenu={setShowMenu}
      />
    </div>
  )
}

export default Header
